/* src/components/Navbar.css */

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #014421;
  /* Customize the background color */
  padding: 10px 0;
  z-index: 1000;
  /* Ensures the navbar stays on top of other elements */
}

.navbar-links {
  list-style: none;
  display: flex;
  justify-content: center;
  /* Centers the navigation links */
  margin: 0;
  padding: 0;
}

.navbar-links li {
  margin: 0 15px;
}

.navbar-links a {
  color: #c0c0c0;
  /* Link text color */
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
}

.navbar-links a.active {
  font-weight: 900;
  color: #eaebf6;
  /* Indicates the active page */
}

body {
  margin: 0;
  padding-top: 60px;
  /* Adjust based on the navbar's height */
}
